<template>
  <v-row v-if="loading" align="center" justify="center" class="fill-height">
    <v-progress-circular
      :size="60"
      :width="5"
      color="primary"
      indeterminate
    ></v-progress-circular>
  </v-row>

  <v-container v-else class="deposit-transaction-details">
    <v-card max-width="740" class="mx-auto px-6 pb-6 pt-4 overflow-hidden">
      <div class="d-block text-center mb-3">
        <img width="60" :src="data.logo" />
      </div>

      <div class="text-center mb-1">
        <span class="font-medium-20 gray13--text me-1">{{
          data.cryptoAmountString
        }}</span>
        <span class="font-medium-20 gray13--text">{{ data.iso }}</span>
      </div>

      <div class="text-center mb-1 d-flex align-center justify-center">
        <span class="font-medium-20 gray8--text me-1">≈</span>
        <span class="font-medium-14 gray8--text"
          >${{ data.localFiatCurrencyString }}</span
        >
      </div>

      <div class="text-center">
        <v-chip
          small
          :color="statusColors.bg_color"
          :text-color="statusColors.text_color"
        >
          <span class="font-regular-10">{{ data.transactionStatusText }}</span>
        </v-chip>
      </div>

      <div
        class="d-sm-flex align-center justify-sm-space-between text-center my-4"
      >
        <div class="font-regular-12 gray9--text">
          {{
            $t(
              "panel.transactionsPage.depositTransactions.depositTransactionDetails.dateAndTime"
            )
          }}
        </div>

        <span class="gray12--text">
          {{ data.transactionTime | $renderDate }}
        </span>
      </div>
      <v-divider></v-divider>

      <template v-if="data.previouslyStatus">
        <div
          class="d-sm-flex align-center justify-sm-space-between text-center my-4"
        >
          <div class="font-regular-12 gray9--text">
            {{
              $t(
                "panel.transactionsPage.depositTransactions.depositTransactionDetails.previousStatus"
              )
            }}
          </div>

          <span class="gray12--text">
            {{ data.previouslyStatusText }}
          </span>
        </div>
        <v-divider></v-divider>
      </template>

      <div
        class="d-sm-flex align-center justify-sm-space-between text-center my-4"
      >
        <div class="font-regular-12 gray9--text">
          {{
            $t(
              "panel.transactionsPage.depositTransactions.depositTransactionDetails.customerPaid"
            )
          }}
        </div>

        <div>
          <span
            class="font-regular-14 me-1"
            :class="
              data.customerPaid === data.cryptoAmount
                ? 'gray12--text'
                : data.customerPaid > data.cryptoAmount
                ? 'success--text'
                : 'error--text'
            "
            >{{ data.customerPaidString }}</span
          >
          <span
            class="font-regular-14"
            :class="
              data.customerPaid === data.cryptoAmount
                ? 'gray12--text'
                : data.customerPaid > data.cryptoAmount
                ? 'success--text'
                : 'error--text'
            "
            >{{ data.iso }}</span
          >
        </div>
      </div>
      <v-divider></v-divider>

      <template v-if="data.customerPaid !== data.cryptoAmount">
        <div
          class="d-sm-flex align-center justify-sm-space-between text-center my-4"
        >
          <div class="font-regular-12 gray9--text">
            {{
              $t(
                "panel.transactionsPage.depositTransactions.depositTransactionDetails.totalAmount"
              )
            }}
          </div>

          <div>
            <span class="font-regular-14 gray12--text me-1">{{
              data.cryptoAmountString
            }}</span>
            <span class="font-regular-14 gray12--text">{{ data.iso }}</span>
          </div>
        </div>
        <v-divider></v-divider>
      </template>
      <!--      <div class="d-sm-flex align-center justify-sm-space-between text-center my-4">-->
      <!--        <div>-->
      <!--          <span class="font-regular-12 gray9&#45;&#45;text me-1">-->
      <!--            {{-->
      <!--              $t(-->
      <!--                "panel.transactionsPage.depositTransactions.depositTransactionDetails.USDValue"-->
      <!--              )-->
      <!--            }}-->
      <!--          </span>-->

      <!--          <v-tooltip-->
      <!--            v-model="showUSDValueTooltip"-->
      <!--            right-->
      <!--            :top="$vuetify.breakpoint.xs"-->
      <!--            color="white"-->
      <!--            content-class="operational-fee-tooltip"-->
      <!--            class="operational-fee-tooltip"-->
      <!--          >-->
      <!--            <template v-slot:activator="{ on, attrs }">-->
      <!--              <v-icon size="16" color="transparent" v-bind="attrs" v-on="on"-->
      <!--                >$info-->
      <!--              </v-icon>-->
      <!--            </template>-->
      <!--            <span class="font-regular-12 gray8&#45;&#45;text">-->
      <!--              {{-->
      <!--                $t(-->
      <!--                  "panel.transactionsPage.depositTransactions.depositTransactionDetails.USDValueTooltip"-->
      <!--                )-->
      <!--              }}-->
      <!--            </span>-->
      <!--          </v-tooltip>-->
      <!--        </div>-->

      <!--        <div>-->
      <!--          <span class="font-medium-20 gray8&#45;&#45;text me-1">≈</span>-->
      <!--          <span class="font-medium-14 gray8&#45;&#45;text"-->
      <!--            >${{ data.localFiatCurrency }}</span-->
      <!--          >-->
      <!--        </div>-->
      <!--      </div>-->
      <!--      <v-divider />-->

      <template v-if="data.transactionStatus !== 'REFUND_SUCCESS'">
        <div
          class="d-sm-flex align-center justify-sm-space-between text-center my-4"
        >
          <div>
            <span class="font-regular-12 gray9--text me-1">
              {{
                $t(
                  "panel.transactionsPage.depositTransactions.depositTransactionDetails.riverPayCommission"
                )
              }}
            </span>
            <span class="font-regular-12 gray9--text">
              ({{ data.riverPayCommissionPercentage }}%)
            </span>
          </div>

          <div>
            <span class="font-regular-14 gray12--text me-1">{{
              data.riverPayCommissionString
            }}</span>
            <span class="font-regular-14 gray12--text">{{ data.iso }}</span>
          </div>
        </div>
        <v-divider></v-divider>
      </template>

      <div
        class="d-sm-flex align-center justify-sm-space-between text-center my-4"
      >
        <div>
          <span class="font-regular-12 gray9--text me-1">
            {{
              $t(
                "panel.transactionsPage.depositTransactions.depositTransactionDetails.operationalFee"
              )
            }}
          </span>

          <v-tooltip
            v-model="showOperationalFeeTooltip"
            right
            :top="$vuetify.breakpoint.xs"
            color="white"
            content-class="operational-fee-tooltip"
            class="operational-fee-tooltip"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon size="16" color="transparent" v-bind="attrs" v-on="on"
                >$info
              </v-icon>
            </template>
            <span class="font-regular-12 gray8--text">
              {{
                $t(
                  "panel.transactionsPage.depositTransactions.depositTransactionDetails.operationalFeeTooltipDesc"
                )
              }}
            </span>
          </v-tooltip>
        </div>

        <div>
          <span class="font-regular-14 gray12--text me-1">{{
            data.operationalFeeString
          }}</span>
          <span class="font-regular-14 gray12--text">{{ data.iso }}</span>
        </div>
      </div>
      <v-divider></v-divider>

      <div
        class="d-sm-flex align-center justify-sm-space-between text-center my-4"
      >
        <div class="font-regular-12 gray9--text">
          {{
            $t(
              "panel.transactionsPage.depositTransactions.depositTransactionDetails.to"
            )
          }}
        </div>

        <span
          class="font-regular-14 gray12--text text-truncate d-inline-block walletAddress"
          >{{ data.walletAddress }}</span
        >
      </div>
      <v-divider></v-divider>

      <div
        class="d-sm-flex align-center justify-sm-space-between text-center my-4"
      >
        <div class="font-regular-12 gray9--text">
          {{
            $t(
              "panel.transactionsPage.depositTransactions.depositTransactionDetails.type"
            )
          }}
        </div>

        <span class="font-regular-14 gray12--text">{{ data.gatewayType }}</span>
      </div>
      <v-divider></v-divider>

      <div
        class="d-sm-flex align-center justify-sm-space-between my-4 text-center align-center"
      >
        <div class="font-regular-12 gray9--text text-no-wrap">
          {{
            $t(
              "panel.transactionsPage.depositTransactions.depositTransactionDetails.businessName"
            )
          }}
        </div>

        <span class="font-regular-14 gray12--text text-right">{{
          data.businessName
        }}</span>
      </div>
      <v-divider></v-divider>

      <div
        class="d-sm-flex align-center justify-sm-space-between text-center my-4"
      >
        <div class="font-regular-12 gray9--text">
          {{
            $t(
              "panel.transactionsPage.depositTransactions.depositTransactionDetails.transactionId"
            )
          }}
        </div>

        <span class="gray12--text text-truncate">{{ data.transactionId }}</span>
      </div>
      <v-divider></v-divider>

      <div v-for="(trx, index) in data.depositDetailResponseDto" :key="index">
        <div
          class="d-sm-flex align-center justify-sm-space-between text-center my-4"
        >
          <div class="font-regular-12 gray9--text">
            {{
              $t(
                "panel.transactionsPage.depositTransactions.depositTransactionDetails.transactionHash"
              )
            }}
            ( {{ index + 1 }} )
          </div>

          <div class="truncate">
            <v-icon
              size="24"
              color="primary"
              class="mx-2 copy-to-clipboard-icon"
              @click="copyToClipboard(trx.transactionHash)"
              >$copyToClipboard</v-icon
            >
            <span class="gray12--text text-truncate mb-1">
              {{ trx.transactionHash }}
            </span>
          </div>
        </div>
        <v-divider></v-divider>
      </div>

      <div
        class="d-sm-flex align-center justify-sm-space-between text-center my-4"
      >
        <div class="font-regular-12 gray9--text">
          {{
            $t(
              "panel.transactionsPage.depositTransactions.depositTransactionDetails.viewExplorer"
            )
          }}
        </div>

        <div>
          <a :href="data.explorerLink" target="_blank" class="font-semiBold-14"
            >{{
              $t(
                "panel.transactionsPage.depositTransactions.depositTransactionDetails.viewExplorer"
              )
            }}
            <v-icon size="16" class="ms-2">$shareArrow</v-icon>
          </a>
        </div>
      </div>
      <v-divider></v-divider>

      <div
        class="d-sm-flex align-center justify-sm-space-between text-center my-4"
      >
        <div class="font-regular-12 gray9--text">
          {{
            $t(
              "panel.transactionsPage.depositTransactions.depositTransactionDetails.orderNumber"
            )
          }}
        </div>

        <span class="gray12--text">{{ data.orderNumber }}</span>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import { ReportService } from "@/services";
import renderDate from "@/mixins/renderDate";
import successIcon from "@/assets/AppIcons/successIcon";

export default {
  name: "deposit-transaction-details",
  components: {},
  props: ["id"],
  mixins: [renderDate],
  data() {
    return {
      loading: false,
      showOperationalFeeTooltip: false,
      showUSDValueTooltip: false,
      data: {},
    };
  },
  computed: {
    statusColors() {
      const data = this.$t("panel.stateStatuses").find(
        (elem) => elem.status === this.data.transactionStatus
      );
      if (data) return data;
      else
        return this.$t("panel.stateStatuses").find(
          (elem) => elem.status === "OTHER"
        );
    },
  },
  mounted() {
    this.getTrxDetails();
  },
  methods: {
    copyToClipboard(hash) {
      if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
        navigator.clipboard.writeText(hash);
      } else {
        const el = document.createElement("textarea");
        el.value = hash;
        el.setAttribute("readonly", "");
        el.style.position = "absolute";
        el.style.left = "-9999px";
        document.body.appendChild(el);
        el.select();
        document.execCommand("copy");
        document.body.removeChild(el);
      }
      this.$toast.success(this.$t("panel.toast.copied"), {
        icon: successIcon,
      });
    },
    getTrxDetails() {
      this.loading = true;
      ReportService.getDepositTrxById(this.id)
        .then((res) => {
          this.data = res.data.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
@import "DepositTransactionDetails";
</style>
